import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"translations",
        "id":"translation_id",
        "subidname":"translation_category_id",
        "options":["edit","insert"],
        "nameSingle":"translation",
        "nameMultiple":"translations",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Key",
                "field":"translation_key",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Translation",
                "field":"translation_value",
                "type":"TextAreaMultiLanguage",
                "required":true,
                "list":false,
            },
            "field3":{
                "name":"Languages",
                "field":"languages",
                "type":"Display",
                "edit":false,
                "list":true,
            }
        }
    }
}